<template>
  <div class="overview-table-actions">
    <!-- <img
      class="overview-table-actions__close"
      src="images/overview-table-actions-close.svg"
      @click="$emit('close-click')"
    /> -->
    <!-- <img
      class="overview-table-actions__refresh"
      src="images/overview-table-actions-refresh.svg"
      @click="$emit('refresh-click')"
    /> -->
    <span class="overview-table-actions__time">Opdateret: {{ time }}</span>
    <!-- <a class="overview-table-actions__button" @click="$emit('export-click')"
      >Eksporter til Excel</a
    > -->
  </div>
</template>

<script>
export default {
  name: "OverviewTableActions",
  props: {
    time: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.overview-table-actions {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overview-table-actions__close {
  margin-right: 8px;
  cursor: pointer;
}
/* .overview-table-actions__refresh {
  margin-right: 20px;
  cursor: pointer;
} */
.overview-table-actions__time {
  margin-right: 20px;
  font-size: 12px;
  font-weight: bold;
}
.overview-table-actions__button {
  font-size: 12px;
  font-weight: bold;
  background-color: #56bae0;
  padding: 14px 14px;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
}
@media screen and (min-width: 1200px) {
  .overview-table-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
