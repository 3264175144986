<template>
    <ul class="pagination">
        <li class="pagination-item">
            <button
                type="button"
                @click="onClickFirstPage"
                :disabled="isInFirstPage"
                class="pagination-btn"
            >
                <i class="fa fa-angle-double-left custom-cls"></i>
            </button>
        </li>

        <li class="pagination-item">
            <button
                type="button"
                @click="onClickPreviousPage"
                :disabled="isInFirstPage"
                class="pagination-btn  custom-pd"
            >
               <i class="fa fa-angle-left custom-cls"></i>
            </button>
        </li>

        <!-- Visible Buttons Start -->

        <li
            class="pagination-item"
            v-for="page in pages"
            :key="page.name"
            
        >
            <button
                type="button"
                @click="onClickPage(page.name)"
                :disabled="page.isDisabled"
                :class="{ active: isPageActive(page.name) }"
                class="pagination-btn"
            >
                {{ page.name }}
            </button>
        </li>
        
        <!-- Visible Buttons End -->

        <li class="pagination-item">
            <button
                type="button"
                @click="onClickNextPage"
                :disabled="isInLastPage"
                class="pagination-btn custom-pd"
            >
               <i class="fa fa-angle-right custom-cls"></i>
            </button>
        </li>

        <li class="pagination-item">
            <button
                type="button"
                @click="onClickLastPage"
                :disabled="isInLastPage"
                class="pagination-btn"
            >
               <i class="fa fa-angle-double-right custom-cls"></i>
            </button>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },    
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: String,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1;
            }

            // When on the last page
            // if (this.currentPage === this.totalPages) {
            //     return this.totalPages - this.maxVisibleButtons;
            // }

            // When inbetween
            return this.currentPage - 1;
        },
        pages() {
            const range = [];

            for (
                let i = this.startPage;
                i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
                i++
            ) 
            {
                range.push({
                name: i,
                isDisabled: i === this.currentPage
                });
            }

            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },
    methods: {
        onClickFirstPage() {
            this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickNextPage() {
            this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit('pagechanged', this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        }
    }
  
};
</script>

<style scoped>
    .pagination {
        list-style-type: none;
    }

    .pagination-item {
        display: inline-block;
    }

    li .active {
        background-color: #1e3f50 !important;
        color: #fff;
    }
    .pagination-btn{
        padding: 9px 12px;
        border-radius: 50%;
        border: 1px solid #fff;
        background-color: #225268;
        font-size: 14px;
        color: #fff;
        width: 40px;
    }
    .custom-cls{
        font-size: 15px;
        color: #fff;
    }
    .custom-pd{
      margin: 0 10px;
    }
</style>