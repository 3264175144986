<template>
  <div class="filter-bar">
    <slot />
  </div>
</template>

<script>
export default {
  name: "FilterBar",
};
</script>

<style>
@media screen and (min-width: 1024px) {
  .filter-bar {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .filter-bar {
    position: relative;
    justify-content: center;
  }
}
</style>
