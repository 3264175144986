<template>
  <div class="">
    <form style="margin:0 !important;">
      <div class="date-range__header">Søgefelter</div>
      <div class="date-range__content">
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Ordrenummer</div>
            <input type="text" class="input-width" v-model="searchdata.order"/>
          </div>
        </div>

        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Navn</div>
            <input type="text" class="input-width" v-model="searchdata.name"/>
          </div>
        </div>
      </div>

      <div class="date-range__content">
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Telefonnummer</div>
            <input type="text" class="input-width" v-model="searchdata.phone"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Adresse</div>
            <input type="text" class="input-width" v-model="searchdata.address"/>
          </div>
        </div>
      </div>

      <div class="date-range__content">
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Postnummer</div>
            <input type="text" class="input-width" v-model="searchdata.zip"/>
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">By</div>
            <input type="text" class="input-width" v-model="searchdata.city"/>
          </div>
        </div>
      </div>

      <!-- <div class="date-range__content">
        <div class="form-field">
          <input 
              type="checkbox"
              @click="selectDate($event)"
              class="checkbox-service"
            />
            <label class="check-lbl">Vælg dato</label>
        </div>
      </div> -->

      <div class="date-range__content">
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Dato Fra</div>
            <input 
              type="date" 
              class="input-width" 
              v-model="searchdata.startdate"
              
            />
          </div>
        </div>
        <div class="form-field">
          <div class="form-field-input-wrapper">
            <div class="date-range-input__header">Dato Til</div>
            <input 
              type="date" 
              class="input-width"  
              v-model="searchdata.enddate" 
              
            />
          </div>
        </div>
      </div>

      <div class="date-range__content">
        <div class="date-range-reset">
          <btn class="refreshbtn btn-ref" label="SØG" type="submit" @click="refresh" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Btn from "@/components/Btn.vue";
export default {
  name: "DateRange",
  components: {
    Btn
  },
  data() {
    // Dummy data
    return {
      searchdata:{
        order : "",
        phone:"",
        name:"",
        address : "",
        zip : "",
        city : "",
        startdate : "",
        enddate : "",
      },

      disable_dates:true
      
    }
  },
  created(){
      var todaydate = new Date();
      var date = new Date();
      var yesterday = new Date(todaydate.setMonth(todaydate.getMonth() - 6));
      var tomorrow = new Date(date.setMonth(date.getMonth() + 1));
      
      var y = new Date(yesterday).getFullYear()+'-'+(new Date(yesterday).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(yesterday).getDate().toString().padStart(2, '0');
      var t = new Date(tomorrow).getFullYear()+'-'+(new Date(tomorrow).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(tomorrow).getDate().toString().padStart(2, '0');

      // console.log('today===----',todaydate);
      // console.log('yesterday===----',y);
      // console.log('tomorrow===----',t);

      var localstartdate = localStorage.getItem("startDate");
      var localenddate = localStorage.getItem("endDate");
      
      if(localstartdate == null || localenddate == null){
        this.searchdata.startdate = y;
        this.searchdata.enddate = t;
      }else{
        this.searchdata.startdate =localStorage.getItem("startDate");
        this.searchdata.enddate = localStorage.getItem("endDate");
      }
  },
  methods: {
    reset() {
      //this.$emit("change",{'startdate':this.startdate,'enddate':this.enddate});
      var todaydate = new Date();
      var date = new Date();
      var yesterday = new Date(todaydate.setDate(todaydate.getDate() - 1));
      var tomorrow = new Date(date.setDate(date.getDate() + 1));
      var y = new Date(yesterday).getFullYear()+'-'+(new Date(yesterday).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(yesterday).getDate().toString().padStart(2, '0');
      var t = new Date(tomorrow).getFullYear()+'-'+(new Date(tomorrow).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(tomorrow).getDate().toString().padStart(2, '0');
      this.searchdata.startdate = y;
      this.searchdata.enddate = t;
    },
    refresh(){
      this.$emit("change",{'searchdata':this.searchdata});
    },
    selectDate(event){
      if(event.target.checked == true){
        this.disable_dates = false;
      }else{
        this.disable_dates = true;
      }
    }
  },
};
</script>

<style>
.date-range {
  /* max-width: 330px; */
  max-width: 470px;
}
.date-range__header {
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 25px;
}
.date-range__content {
  display: flex;
  justify-content: space-between;
}
.date-range-input {
  margin-right: 10px;
}
.date-range-input__header {
  font-size: 14px;
  margin-bottom: 8px;
}
.date-range-input__field {
  background: none;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  padding: 14px 10px;
  width: 100%;
  text-align: center;
}
.date-range-reset {
  font-size: 12px;
  font-weight: bold;
  margin-top: 14px;
  /* text-align: center; */
  width: 100px;
  padding-top: 23px;
  cursor: pointer;
}
.overview-table-actions__refresh {
  /* margin-top: 20px;
  margin-right: 20px;
  cursor: pointer; */
  margin-top: -8px;
}
.refreshbtn{
  margin-top: -12px;
  /* margin-left: -20px; */
}
.form-field {
  margin-bottom: 10px;
  width: 100% ;
  position: relative ;
  margin-right: 50px !important;
}
.form-field input {
  background: none;
  border: 1px solid #fff;
  border-radius: 8px;
  color: #fff;
  padding: 14px 20px 14px 10px;
}
.input-width{
  width: 360px !important;
}
.form-field-input-wrapper {
  position: relative;
}
.checkbox-service {
  height: 25px;
  width: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.check-lbl{
  margin-left: 12px;
}
@media screen and (min-width: 1200px) {
  .date-range {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 520px){
  .date-range__content {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 420px){
   .btn-ref{
      padding: 0 14px;
   }
}
@media (max-width: 375px){
  .input-width {
  width: 340px !important;
   }
}



</style>
