<template>
  <div class="overview">
    <row background-color="#1d4962">
      <filter-bar>
        <date-range
          @change="dateRangeChange($event)" 
          @refresh-click="refreshClick" 
        />
        <!-- <status-filter
          :items="statusFilter.statuses"
          :current="statusFilter.current"
          @toggle="statusFilterToggle"
        />--> 
      </filter-bar> 
    </row>
    <row>
      <overview-table-bar>
        <!-- <overview-table-headline>{{ shipments.length }} forsendelser</overview-table-headline> -->
        <!-- <overview-table-search @filterSearch="filterSearch($event)" /> -->
        <overview-table-actions
          :time="updatedtime"
          @close-click="closeClick"
          @export-click="exportClick"
        />
      </overview-table-bar>
    </row>
    <row>
      <label class="pagesize-label">antal visninger</label>
    </row>
    <row>
      <select
        v-model="pagesize"
        class="perpage-cls" 
      >
        <option 
          v-for="perpage in perPageOptions" 
          :key="perpage"
        >{{ perpage }}</option>
      </select>

      <pagination
        :totalPages="totalPages"
        :perPage="pagesize"
        :currentPage="currentPage"
        @pagechanged="onPageChange"
        class="pagination-cls"
      />
    </row>
    <row scroll class="paginate-row">
      <overview-table
        :shipments="shipments"
        @shipment-click="shipmentClick"
        @label-click="labelClick"
        @location-click="locationClick"
        @photo-click="photoClick"
      />
    </row>
  </div>
</template>

<script>
import Row from "@/components/Row";
import Pagination from '@/components/Pagination.vue';
//import vSelect from "vue-select";
import FilterBar from "@/components/FilterBar";
import DateRange from "@/components/DateRange";
//import StatusFilter from "@/components/StatusFilter";
import OverviewTableBar from "@/components/OverviewTableBar";
//import OverviewTableHeadline from "@/components/OverviewTableHeadline";
//import OverviewTableSearch from "@/components/OverviewTableSearch";
import OverviewTableActions from "@/components/OverviewTableActions";
import OverviewTable from "@/components/OverviewTable";
import axios from "axios";
//import Row from '../components/Row.vue';


export default {
  name: "Overview",
  components: {
    Row,
    FilterBar,
    DateRange,
    //StatusFilter,
    OverviewTableBar,
    //OverviewTableHeadline,
    //OverviewTableSearch,
    OverviewTableActions,
    OverviewTable,
    Pagination,
    //vSelect
  },
  data() {
    
    // Dummy data
    return {
      pagesize : "10",
      totalPages : 0,
      filter : "",
      perPageOptions: [5,10, 20, 40, 60, 80, 100],
      currentPage: 1,
      statusFilter: {
        current: [],
        // statuses: [
        //   { id: "ready", title: "Klar" },
        //   { id: "booked", title: "Booket" },
        //   { id: "delivering", title: "Undervejs" },
        //   { id: "delivered", title: "Leveret" },
        //   { id: "error", title: "Fejl" },
        //   { id: "draft", title: "Kladde" },
        //   { id: "delayed", title: "Forsinket" },
        //   { id: "deleted", title: "Slettet" },
        // ],
      },
      shipments: [
        // {
        //   id: "",
        //   status: "delivered",
        //   ordernumber: "",
        //   createdDate: "",
        //   createdTime: "",
        //   reference: "BK",
        //   orderer: {
        //     name: "",
        //     number: "",
        //     person: "",
        //   },
        //   pickup: {
        //     name: "",
        //     address: "",
        //     zip: "",
        //     city: "",
        //   },
        //   recipient: {
        //     name: "",
        //     address: "",
        //     zip: "",
        //     city: "",
        //   },
        //   deliveryDate: "",
        //   pickupDate: "01-12-2020",
        //   weight: "",
        //   volume: "",
        // },
      ],
      offset : 0,
      order : "",
      phone:"",
      name:"",
      address : "",
      zip : "",
      city : "",
      startdate : "",
      enddate : "",
      mytoken: "",
      shipmentdata: [],
      updatedtime:"",
      filtersearch:[]
    };
    
  },
  created(){
    this.mytoken = localStorage.getItem("token");
    if(this.mytoken == null || this.mytoken == ""){
      this.loggedIn = false;
      this.$router.push({ name: 'Login'}); 
    }else{

      this.mytoken = localStorage.getItem("token");
      
      var config = {
        method: "get",
        url: "https://engine.bftlogistik.dk:7000/partner/v1/checktoken/"+this.mytoken
      };
      axios(config)
      .then((response) => {
        if(response.data.token.status == true){
          this.loggedIn = true;
          var todaydate = new Date();
          var date = new Date();

          var yesterday = new Date(todaydate.setMonth(todaydate.getMonth() - 6));
          var tomorrow = new Date(date.setMonth(date.getMonth() + 1));
          
          var y = new Date(yesterday).getFullYear()+'-'+(new Date(yesterday).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(yesterday).getDate().toString().padStart(2, '0');
          var t = new Date(tomorrow).getFullYear()+'-'+(new Date(tomorrow).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(tomorrow).getDate().toString().padStart(2, '0');

          var localstartdate = localStorage.getItem("startDate");
          var localenddate = localStorage.getItem("endDate");

          if(localstartdate == null || localenddate == null){
            this.startdate = y;
            this.enddate = t;
            
          }else{
            this.startdate =localStorage.getItem("startDate");
            this.enddate = localStorage.getItem("endDate");
          }
          var localpagesize = localStorage.getItem("pageSize");

          if(localpagesize != null){
            this.pagesize = localStorage.getItem("pageSize");
          }

          var localcurrentpage = localStorage.getItem("currentPage");

          if(localcurrentpage != null){
            this.currentPage = parseInt(localStorage.getItem("currentPage"));
          }
          this.getshipmentdata();
        }
      })
      .catch(function (error) {
        console.log(error);
        if(error.response.status == 404)
        {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
          localStorage.removeItem("pageSize");
          localStorage.removeItem("currentPage");
          localStorage.setItem("loggedIn", false);
          location.reload();
        }
      });
    }
  },
  watch: {
    pagesize: function (val) {
      this.pagesize = val;
      localStorage.setItem("pageSize",this.pagesize);
      this.getshipmentdata();
    },
    currentPage: function (val) {
      this.currentPage = val;
      localStorage.setItem("currentPage",this.currentPage);
      this.getshipmentdata();
    },
  },
  methods: {
    
    onPageChange(page) {
      //console.log(page)
      this.currentPage = page;
    },

    getshipmentdata(){
      this.shipments = [];
      this.mytoken = localStorage.getItem("token");
      const offset = this.pagesize * (this.currentPage - 1);
      //console.log(offset);
      this.offset = offset;

      var config = {
        method: "get",
        url: "https://engine.bftlogistik.dk:7000/partner/v1/getshipments/"+ this.mytoken +
        "?offset=" + this.offset +
        "&pagesize=" + this.pagesize +
        "&order=" + this.order +
        "&phone=" + this.phone +
        "&name=" + this.name +
        "&address=" + this.address +
        "&zip=" + this.zip +
        "&city=" + this.city +
        "&startdate=" + this.startdate +
        "&enddate=" + this.enddate 
      };
      axios(config)
      .then((response) => {
        var d = new Date();
        
        this.updatedtime = d.getHours().toString()+":"+d.getMinutes().toString().padStart(2, '0');
        //console.log("current time====>>>",d.getHours().toString(),":",d.getMinutes().toString().padStart(2, '0'));
        //console.log(response.data.shipments[0].length);
        if(response.data.shipments.recordset.length == 0)
        {
          this.shipments = [];
        }else{
          this.shipments = [];
          var countpage = response.data.shipments.MaxRecords;
          this.totalPages = Math.ceil(countpage/this.pagesize);
          this.shipmentdata = response.data.shipments.recordset;
          response.data.shipments.recordset.forEach((element)=> {
            
            var timeplit = [];
            if(element.DelTime == "" || element.DelTime == null){
              timeplit = ["08","21"]; 
            }else{
              timeplit = element.DelTime.split("-");
            }

            this.shipments.push(
              {
                id: element.ID,
                status: "delivered",
                ordernumber : element.CustomerOrderRef,
                createdDate : new Date(element.crtDate).getDate().toString().padStart(2, '0')+'-'+(new Date(element.crtDate).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(element.crtDate).getFullYear(),
                createdTime : new Date(element.crtDate).getHours()+':'+new Date(element.crtDate).getMinutes(),
                DestTelephone_Mob :element.DestTelephone_Mob,
                reference: '',
                orderer : { 
                  name: '',
                  number: '',
                  person: ''
                },
                pickup : { 
                  name: '',
                  address: '',
                  zip: '',
                  city: ''
                },
                recipient : { 
                  name: element.DestName,
                  address: element.DestAdr,
                  zip: element.DestZip,
                  city: element.DestCity
                },
                deliveryDate : new Date(element.Date).getDate().toString().padStart(2, '0')+'-'+(new Date(element.Date).getMonth()+1).toString().padStart(2, '0')+'-'+new Date(element.Date).getFullYear(),
                deliveryTime : timeplit[0]+':00 - '+timeplit[1]+':00',
                pickupDate: '',
                weight : Math.round(element.Weight_actual),
                volume : Number(element.Volumen_actual).toFixed(3),
                Colli : element.Colli
              },
            );
            this.filtersearch = this.shipments;
            //console.log(this.filtersearch);
          });

        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    dateRangeChange(event) {
      console.log("DateRange change",event);
      this.order = event.searchdata.order;
      this.phone = event.searchdata.phone;
      this.zip = event.searchdata.zip;
      this.name = event.searchdata.name;
      this.city = event.searchdata.city;
      this.address = event.searchdata.address;

      this.startdate = event.searchdata.startdate;
      localStorage.setItem("startDate", this.startdate);
      this.enddate = event.searchdata.enddate;
      localStorage.setItem("endDate", this.enddate);
      this.getshipmentdata();
    },
    statusFilterToggle(status) {
      console.log("StatusFilter toggle");
      const indexInArray = this.statusFilter.current.indexOf(status);
      if (indexInArray > -1) {
        this.statusFilter.current.splice(indexInArray, 1);
      } else {
        this.statusFilter.current.push(status);
      }
    },
    filterSearch(event){
      console.log(event);
      if(event !== '') {
        this.shipments = this.filtersearch;
        this.shipments = this.shipments.filter(p => 
          p.id.toString().toLowerCase().includes(event.toString().toLowerCase()) ||
          p.ordernumber.toString().toLowerCase().includes(event.toString().toLowerCase()) ||
          p.recipient.address.toString().toLowerCase().includes(event.toString().toLowerCase()) ||
          p.recipient.city.toString().toLowerCase().includes(event.toString().toLowerCase()) ||
          p.recipient.zip.toString().toLowerCase().includes(event.toString().toLowerCase()) ||
          p.recipient.name.toString().toLowerCase().includes(event.toString().toLowerCase())
        )
      } else {
        this.shipments = this.filtersearch;
      }
      console.log(this.shipments);
      return this.shipments;
    },
    shipmentClick(id) {
      //console.log("Shipment click", id);
      this.$router.push({ path:'/trackadvanced' ,query:{ number:id } });
    },
    labelClick(id) {
      console.log("Label click", id);
    },
    locationClick(id) {
      console.log("Location click", id);
    },
    photoClick(id) {
      console.log("Photo click", id);
    },
    closeClick() {
      console.log("Close click");
    },
    refreshClick() {
      console.log("Refresh click");
      location.reload();
    },
    exportClick() {
      console.log("Export click");
    },
  },
};
</script>
<style scoped>
  .perpage-cls{
    padding: 10px 20px;
    background-color: #225268;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    margin: 1em 0 1em 1em;
    border: 1px solid #fff;
  }
  .pagination-cls{
    float: right;
    margin-right: 1em;
  }
  .paginate-row{
    position: relative;
    top: -30px;
  }
  .pagesize-label{
    position: absolute;
    left: 30px;
  }

  @media (max-width:375px){
      .pagination-cls{
        width: 360px;
      }
  }
</style>
