<template>
  <table class="overview-table">
    <tr class="overview-table-header">
      <th v-for="header in columnHeaders" :key='header.name' @click="sorting(header.sortcode)">
          {{ header.name }}
          <!-- <button class="shorting_arrow"></button> -->
      </th>
      <!-- <th>Status</th>
      <th>Ordrenr.</th>
      <th>Modtager</th>
      <th>Lev. dato</th>
      <th>Lev. tid</th>
      <th>Vægt/str.</th>
      <th>Oprettet</th>
      <th>Forsendelses ID</th>
      <th>Ref</th>
      <th>Bestiller</th>
      <th>Afh. Adresse</th>
      <th>Afh. dato</th>
      <th>Label</th>
      <th>Track & Trace</th>  -->
    </tr>
    <tr
      v-for="shipment in shipmentsss"
      :key="shipment.id"
      class="overview-table-row"
      @click="$emit('shipment-click', shipment.id)"
    >
      <!-- ordernumber -->
      <td class="overview-table__ordernumber">{{ shipment.ordernumber }}</td>

      <!-- shipmentid -->
      <!-- <td class="overview-table__ordernumber">{{ shipment.id }}</td> -->

      <!-- delivery date -->
      <td class="overview-table__delivery-date">{{ shipment.deliveryDate }}</td>

      <!-- delivery time -->
      <td class="overview-table__delivery-date">{{ shipment.deliveryTime }}</td>

      <!-- name -->
      <td>
        <div class="overview-table__recipient-name">
          {{ shipment.recipient.name }}
        </div>
      </td>

      <!-- address -->
      <td>
        <div class="overview-table__recipient-address">
          {{ shipment.recipient.address }}
        </div>
        <div class="overview-table__recipient-zip-city">
          {{ shipment.recipient.zip }} {{ shipment.recipient.city }}
        </div>
      </td>

      <!-- telephone number -->
      <td class="overview-table__delivery-date">{{ shipment.DestTelephone_Mob }}</td>

      <!-- created date and time -->
      <td>
        <div class="overview-table__created-time">
          {{ shipment.createdTime }}
        </div>
        <div class="overview-table__created-date">
          {{ shipment.createdDate }}
        </div>
      </td>

      <!-- type -->
      <!-- <td class="overview-table__delivery-date"></td> -->

      <!-- total packages -->
      <td class="overview-table__delivery-date">{{ shipment.Colli }}</td>

      <!-- weight -->
      <td>
        <div class="overview-table__weight">{{ shipment.weight }}</div>
      </td>

      <!-- volume -->
      <td>
        <div class="overview-table__volume">{{ shipment.volume }}</div>
      </td>

      <!-- <td class="overview-table__status">
        <img :src="`images/filtericons/${shipment.status}--inverted.svg`" />
      </td>

      <td class="overview-table__actions">
        <img
          class="overview-table-marker"
          src="images/overview-table-marker.svg"
          @click.stop="$emit('location-click', shipment.id)"
        />
      </td>

      <td class="overview-table__actions">
        <img
          class="overview-table-camera"
          src="images/overview-table-camera.svg"
          @click.stop="$emit('photo-click', shipment.id)"
        />
      </td> -->

      <!-- label -->
      <td class="overview-table__label">
        <img
          src="images/overview-table-barcode.svg"
          @click.stop="$emit('label-click', shipment.id)"
        />
      </td>

    </tr>
  </table>
</template>

<script>
  export default {
    name: "OverviewTable",
    props: {
      shipments: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return { 
        currentSort: '',
        currentSortDir: 'asc',
        columnHeaders : [
          // { name: 'Status', sortcode : '' },
          // { name: 'Ordrenr.', sortcode : 'ordernumber' },
          // { name: 'Modtager', sortcode : 'name' },
          // { name: 'Lev. dato', sortcode : 'deliveryDate' },
          // { name: 'Lev. tid', sortcode : '' },
          // { name: 'Vægt/str.', sortcode : '' },
          // { name: 'Oprettet', sortcode : 'createdDate' },
          // { name: 'Forsendelses ID', sortcode : 'id' },
          // { name: 'Ref', sortcode : '' },
          // { name: 'Bestiller', sortcode : '' },
          // { name: 'Afh. Adresse', sortcode : '' },
          // { name: 'Afh. dato', sortcode : '' },
          // { name: 'Label', sortcode : '' },
          // { name: 'Track & Trace', sortcode : '' }
          
          { name: 'Ordrenr.', sortcode : 'ordernumber' },
          //{ name: 'Forsendelses ID.', sortcode : '' },
          { name: 'Lev. dato', sortcode : 'deliveryDate' },
          { name: 'Lev. tid', sortcode : '' },
          { name: 'Navn', sortcode : 'name' },
          { name: 'Adresse', sortcode : '' },
          { name: 'Telefonnummer', sortcode : '' },
          { name: 'Oprettet', sortcode : 'createdDate' },
          // { name: 'Type', sortcode : '' },
          { name: 'Antal kolli', sortcode : '' },
          { name: 'Vægt (kg)', sortcode : '' },
          { name: 'Volumen (M3)', sortcode : '' },
          // { name: 'Status', sortcode : '' },
          // { name: 'Geopunkt', sortcode : '' },
          // { name: 'Billeder', sortcode : '' },
          { name: 'Label', sortcode : '' },
        ]
      };
    },
 
    computed: {
      shipmentsss() {
        return this.shipments.filter(a => {
      
          return (a)
        })
          .sort((a, b) => {
          if (this.currentSortDir === 'asc') {
            if(this.currentSort == 'deliveryDate' || this.currentSort == 'createdDate')
            {
              //console.log(a[this.currentSort]);
              return new Date(a[this.currentSort]) - new Date(b[this.currentSort]);
            }  
              
            return a[this.currentSort] >= b[this.currentSort];      
          }

            // if(this.currentSort == 'deliveryDate' ||this.currentSort == 'createdDate')
            //           return new Date(a[this.currentSort]) <= new Date(b[this.currentSort]); 
          return a[this.currentSort] <= b[this.currentSort];
        
        })
      },
    },
    methods:{
      sorting(sortcode){
        if(this.currentSort == sortcode){
          this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
        }else{
          this.currentSort = sortcode;
        }
        console.log( 'diff col: '+this.currentSort,this.currentSortDir );
      }
    }
  };
</script>

<style>
  .overview-table {
    border: none;
    border-spacing: 1px 10px;
  }
  .overview-table th {
    /* padding: 12px; */
    padding: 20px;
  }
  .overview-table-row {
    cursor: pointer;
  }
  .overview-table-row td {
    background-color: #fff;
    color: #1d4962;
    font-size: 14px;
    padding: 10px;
  }
  .overview-table-row td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .overview-table-row td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .overview-table__status,
  .overview-table__label {
    text-align: center;
  }
  .overview-table-marker {
    margin-right: 15px;
  }
  /* .overview-table-camera {
  } */
  .overview-table__created-date,
  .overview-table__delivery-date,
  .overview-table__pickup-date,
  .overview-table__pickup-zip-city,
  .overview-table__recipient-zip-city {
    white-space: nowrap;
  }
  .overview-table__actions {
    text-align: center;
    min-width: 100px;
  }
  .shorting_arrow{
    background: url("../assets/shorting-arrow.svg");
  }
</style>
