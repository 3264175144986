<template>
  <div class="overview-table-bar">
    <slot />
  </div>
</template>

<script>
export default {
  name: "OverviewTableBar",
};
</script>

<style>
@media screen and (min-width: 768px) {
  .overview-table-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1200px) {
  .overview-table-bar {
    position: relative;
    flex-wrap: nowrap;
    justify-content: center;
  }
}
</style>
